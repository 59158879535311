import * as React from 'react';
import { graphql, Link } from 'gatsby';
// import Img, { FluidObject } from 'gatsby-image';

import { LocationPagesQuery } from '../../graphql-types';
import {
  formatHoursOfOperation,
  HoursOfOperation,
} from '../helpers/formatHoursOfOperation';
import { Container } from '../components/Container';
import { Layout } from '../components/Layout';
import { Section } from '../components/Section';
import SEO from '../components/seo';
import { Address } from '../components/Address';
import { Map } from '../components/Map';
import { RichText } from '../components/RichText';
import { formatPhoneNumber } from '../helpers/formatPhoneNumber';
import styles from './location.module.css';

interface LocationPagesProps {
  data: LocationPagesQuery;
}

export const query = graphql`
  query LocationPages($id: String!) {
    contentfulLocation(id: { eq: $id }) {
      id
      slug
      name
      title
      email
      phoneNumber
      googlePlaceId
      address {
        streetAddress
        secondaryAddress
        city
        state
        zipcode
      }
      hoursOfOperation {
        close {
          day
          time
        }
        open {
          day
          time
        }
      }
      body {
        json
      }
      # image {
      #   localFile {
      #     childImageSharp {
      #       fluid(maxWidth: 1076, toFormat: JPG) {
      #         ...GatsbyImageSharpFluid_withWebp
      #       }
      #     }
      #   }
      # }
    }
  }
`;

export default ({ data }: LocationPagesProps) => {
  const l = data.contentfulLocation;
  const title =
    l?.title ||
    (l?.address?.city &&
      l?.address?.state &&
      `Our ${l?.address?.city}, ${l?.address?.state} location`) ||
    (l?.name as string);

  const hours = formatHoursOfOperation(l?.hoursOfOperation as HoursOfOperation);
  const bodyJson = l?.body?.json;

  return (
    <Layout>
      <SEO title={title} />

      <Section>
        <Container>
          <h1>{title}</h1>

          <div className={styles.details}>
            <div className={styles.info}>
              {l?.address &&
                l?.address.streetAddress &&
                l?.address.city &&
                l?.address.state &&
                l?.address.zipcode && (
                  <>
                    <h3>Address</h3>
                    <Address address={l.address} />
                  </>
                )}

              {l?.phoneNumber && (
                <>
                  <h3>Phone number</h3>
                  <p>
                    <a href={`tel:${l.phoneNumber}`}>
                      {formatPhoneNumber(l.phoneNumber)}
                    </a>
                  </p>
                </>
              )}

              {hours && (
                <>
                  <h3>Hours</h3>

                  <ul className={styles.hours}>
                    {hours.map(h => (
                      <li key={h}>{h}</li>
                    ))}
                  </ul>
                </>
              )}
            </div>

            {l?.googlePlaceId && (
              <div className={styles.map}>
                <Map googlePlaceId={l.googlePlaceId} height="460px" />
              </div>
            )}
          </div>

          <RichText json={bodyJson} />

          <hr className={styles.hr} />

          <Link to="/locations" className={styles.back}>
            Back to our locations
          </Link>
        </Container>
      </Section>
    </Layout>
  );
};
